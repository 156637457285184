.button-base {
  padding: 5px 10px;
  transform: scale(0.7);
  border: none;
  color: rgb(80, 80, 80); /* 調整為較深的字體顏色 */
  background-color: #f0f0f0; /* 較亮的背景色 */
  border-radius: 5px;
}

.button-base:hover {
  color: #333333; /* 深灰色字體，淺色背景更突出 */
  background-color: #d0d0d0; /* 增加 hover 背景色，方便辨識 */
}

.button-base:active {
  color: #333333;
  background-color: #bcbcbc; /* 鼠標按下時的亮灰色背景 */
}

.button-code-copy-base {
  transform-origin: top right;
  transform: scale(0.7);
  border: none;
  background-color: #f0f0f0; /* 預設背景色 */
}

.button-code-copy-base:active {
  background-color: #bcbcbc; /* 鼠標按下時的亮灰色背景 */
}
