/* src/components/ChatMessage.css */
.code-style-body {
  position: relative;
  background-color: #f5f5f5; /* 變更為淺色背景 */
  font-size: 0.85em; /* 設定字型大小 */
  padding: 5px 10px;
  border-top-right-radius: 5px; /* 右上圓弧 */
  color: #333333; /* 深灰字體顏色，適合淺色背景 */
}

.code-style-body > pre {
  margin: 0;
}

.code-style-bottom {
  background-color: #e0e0e0; /* 調整為淺色背景 */
  font-size: 0.7em; /* 設定字型大小 */
  font-style: italic;
  padding: 5px 10px;
  border-bottom-left-radius: 5px; /* 左下圓弧 */
  border-bottom-right-radius: 5px; /* 右下圓弧 */
  color: #333333; /* 深灰文字顏色，適合淺色背景 */
  text-align: right;
}

.code-style-top {
  background-color: #e0e0e0; /* 調整為淺色背景 */
  font-size: 0.7em; /* 設定字型大小 */
  font-style: italic;
  padding: 5px 10px;
  border-top-left-radius: 5px; /* 左下圓弧 */
  border-top-right-radius: 5px; /* 右下圓弧 */
  color: #333333; /* 深灰文字顏色 */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hidden {
  display: none;
}

.markdown-wrapper > * {
  margin: 0px;
}

.markdown-wrapper p {
  margin: 3px;
}
